.SecondaryPage71c2075a21104d50B787B371818a6683 .text-content {
  padding: 0 1rem;
}
.PageQuoteEf2b7d808a8a441a83c06c623d93385a {
  display: flex;
  flex-wrap: wrap;
}
.MarketingBody0f76cab95c324cb4923309b8cd33ec0c {
  padding: 2em;
  background-color: #f9f5e3;
}
.ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa {
  background-color: white;
  color: #030C22;
  position: relative;
}

.ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg);
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
}

.ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa .header-top {
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  padding: 0 1.5rem;
  position: relative;
}

.ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa .header-top .linked-logo {
  display: flex;
  justify-content: center;
}

.ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa .header-top .logo {
  width: 8rem;
}

.ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa .header-top .navigation {
  margin: 1rem;
  margin-top: 2.25rem;
}

.ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa .header-top .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  font-family: Montserrat;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: #500064;
}

.ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa .header-top .NavigationMenuButton {
  color: black;
  opacity: 0.5;
  display: flex;
}

.ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa .header-top .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

.SideCar {
  background-color: #ccc;
  padding: 2rem;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #500064;
  font-family: Montserrat;
  text-transform: uppercase;
}

.SideCar .Navigation .Link {
  display: block;
}
.SideCar .Navigation .Link:hover, .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

/* Larger screens */

@media (min-width: 900px) {
  .ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa .header-top .linked-logo {
    flex-grow: 1;
  }
  .ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa .header-top .logo {
    width: 9rem;
  }

  .ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa .header-top .navigation {
    flex-grow: 4;
  }

  .ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa .header-top .Navigation {
    display: flex;
  }

  .ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa .header-top .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa .header-top {
    padding: 0 1.75rem;
    height: 5rem;
  }

  .ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa .header-top .logo {
    width: 10rem;
  }
}
.DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 7rem;
  padding: 1rem;
  position: relative;
}

.DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b .linked-logo {
  display: flex;
  justify-content: center;
}

.DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b .logo {
  width: 10rem;
}

.DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b .navigation {
  margin: 1rem;
  margin-top: 2.25rem;
}

.DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  font-family: Montserrat;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: #500064;
}

.SideCar {
  background-color: #ccc;
  padding: 2rem;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #500064;
  font-family: Montserrat;
  text-transform: uppercase;
}

.SideCar .Navigation .Link {
  display: block;
}
.SideCar .Navigation .Link:hover, .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

.DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b .NavigationMenuButton {
  color: black;
  opacity: 0.5;
  display: flex;
}

.DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

/* Show/hide navigation */

@media (min-width: 900px) {
  .DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b {
    padding: 2.25rem 1.75rem 1rem 1.75rem;
    height: 9rem;
  }

  .DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b .linked-logo {
    flex-grow: 1;
  }
  .DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b .logo {
    width: 12rem;
  }

  .DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b .navigation {
    flex-grow: 4;
  }

  .DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b .Navigation {
    display: flex;
  }

  .DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b .Navigation {
    font-size: 120%;
  }
  .DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b .logo {
    width: 15.25rem;
  }
}
.MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263 {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 7rem;
}

.MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263 .MarketingCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}

@media (min-width: 768px) {
  .MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263 .MarketingCard {
    gap: 6rem;
  }
}

.MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263 .MarketingCard .ImageOverlay {
}

.MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263 .MarketingCard img {
  max-height: 400px;
}

@media (min-width: 768px) {
  .MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263 .CardTextContent {
    width: 25rem;
  }
}

.MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263 .CardHeading {
  color: #500064;
  font-family: Bebas Neue;
  font-size: 3rem;
  letter-spacing: 0.1rem;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
}
@media (min-width: 768px) {
  .MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263 .CardHeading {
    padding-left: 0;
    padding-right: 0;
    line-height: 1.5;
  }
}

.MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263 .CardText {
  font-family: Montserrat;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
}

.MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263 .ButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

@media (min-width: 768px) {
  .MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263 .ButtonWrapper {
    justify-content: flex-start;
  }
}

.MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263 .Button {
  background-color: #780050;
  color: white;
  font-family: 'Bebas Neue'; 
  font-weight: 700; 
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
}
.SecondaryMarketingHeroB788f542187246f4Bd45A69f7869279a {
  align-items: center;
  color: #030C22;
  display: flex;
  flex-grow: 1;
  padding-left: 5rem;
}

.SecondaryMarketingHeroB788f542187246f4Bd45A69f7869279a .title {
  color: #500064;
  font-family: 'Bebas Neue';
  font-size: 300%;
  font-weight: 500;
  line-height: 100%;
}

@media (min-width: 640px) {
  .SecondaryMarketingHeroB788f542187246f4Bd45A69f7869279a .title {
    font-size: 400%;
  }
}

.SecondaryMarketingHeroB788f542187246f4Bd45A69f7869279a .button > a {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 110%;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.5rem 2rem;
  background-color: #ed2e38;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.SecondaryMarketingHeroB788f542187246f4Bd45A69f7869279a .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.HomeHeader63f9b514F5ba40978766Afd4f559874d {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
  color: #030C22;
}

.HomeHeader63f9b514F5ba40978766Afd4f559874d .background-image {
  opacity: 0.5;
}

.HomeHeader63f9b514F5ba40978766Afd4f559874d .header-top-wrapper {
  position: relative;
}

.HomeHeader63f9b514F5ba40978766Afd4f559874d .header-top-opacity {
  opacity: .8;
  background-color: white;
  position: absolute;
  inset: 0;
}

.HomeHeader63f9b514F5ba40978766Afd4f559874d .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.SecondaryNav7e3eb7309c134650Ba79Fd03adb94a35 {
  padding: 1rem 0;
}
.DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 {
  background-color: #500064;
  color: white;
  min-height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem;
  font-family: Montserrat;
}

.DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 > * {
  text-align: center;
}

.DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 .Navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 .Navigation .Link:hover {
  text-decoration-line: underline;
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 > .logo-wrapper {
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 .LogoWrapper {
  font-size: 90%;
}

.DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 .LogoWrapper {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 .LogoWrapper:hover {
    --tw-text-opacity: 1;
    color: rgb(0 194 152 / var(--tw-text-opacity));
}

.DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 .Logo {
  height: 40px;
  width: 150px;
  fill: currentColor;
}

.DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 .GroupCopyright {
  font-size: 90%;
}

.DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 .GroupFlowTag .PoweredBy {
  font-size: 90%;
}
.AboutLevelingPage641ae0569abd48f29d3f9c71d8e5d235 .Button {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  background: rgb(120, 0, 80);
  letter-spacing: 0.1rem;
}
.MessagePages09b626e64a4c418d8a0fA3dbea849cff {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.HomePageHero6010d128Cf2b4d49B64b8cca6eb90db5 {
  color: #030C22;
  min-height: 35rem;
  padding: 1.5rem;
  position: relative;
  text-align: left;
}

@media (min-width: 768px) {
  .HomePageHero6010d128Cf2b4d49B64b8cca6eb90db5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.HomePageHero6010d128Cf2b4d49B64b8cca6eb90db5 .title {
  color: black;
  font-family: 'Bebas Neue';
  font-size: 300%;
  font-weight: 500;
  line-height: 4rem;
  margin-bottom: 1.2rem;
  letter-spacing: 0.15rem;
  text-align: center;
}

.HomePageHero6010d128Cf2b4d49B64b8cca6eb90db5 .button {
  display: flex;
  justify-content: center;
}

.HomePageHero6010d128Cf2b4d49B64b8cca6eb90db5 .button > a {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: 'Bebas Neue';
  font-weight: 600;
  font-size: 150%;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.75rem 3rem;
  background-color: #780050;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.HomePageHero6010d128Cf2b4d49B64b8cca6eb90db5 .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 900px) {
  .HomePageHero6010d128Cf2b4d49B64b8cca6eb90db5 .title {
    margin-top: 2.2rem;
    text-align: left;
  }

  .HomePageHero6010d128Cf2b4d49B64b8cca6eb90db5 .button {
    display: block;
    justify-content: start;
  }
}
.SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d {
  height: 18rem;
  position: relative;
  color: #030C22;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/spp/images/sky-background.jpg);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -10;
}

.SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d .header-top-wrapper {
  position: relative;
}

.SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d .header-top-opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: white;
  z-index: 0;
}

.SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d .header-top {
  z-index: 1;
  position: relative;
}
.HomePageAfd0152e8e8c4fe484216d3ab7e2651a {
  display: flex;
  flex-direction: column;
}

.WelcomeBackPopUp {
  padding: 2rem;
  position: relative;
  background-color: white;
  box-shadow: 5px 5px 5px rgb(50 50 50 / 20%);
}

.WelcomeBackPopUp .CloseButton {
  color: black;
}

.WelcomeBackPopUp .BackgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(https://mp1md-pub.s3.amazonaws.com/spp/images/page-background-photo.jpg);
  opacity: 0.5;
  background-position: center;
  background-size: cover;
}

.WelcomeBackPopUp .Body {
  position: relative;
}

.WelcomeBackPopUp .Button {
  background-color: #780050;
  color: white;
  font-family: 'Bebas Neue';
  font-size: 120%;
  letter-spacing: 0.05rem;
  padding: 0.5rem 2rem;
  text-align: center;
}

.HomePageAfd0152e8e8c4fe484216d3ab7e2651a .PageQuote {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.HomePageAfd0152e8e8c4fe484216d3ab7e2651a .PageQuote > * {
  flex-grow: 1;
}

.HomePageAfd0152e8e8c4fe484216d3ab7e2651a .PageQuote .Image {
  object-fit: cover;
}

.HomePageAfd0152e8e8c4fe484216d3ab7e2651a .PageQuote .TextContent {
  background-color: #6950a8;
  color: white;
  padding: 3rem;
}

.HomePageAfd0152e8e8c4fe484216d3ab7e2651a .PageQuote .Title {
  font-family: "Bebas Neue";
  font-size: 350%;
  line-height: 100%;
}

.HomePageAfd0152e8e8c4fe484216d3ab7e2651a .PageQuote .Text {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 125%;
}

@media (min-width: 900px) {
  .HomePageAfd0152e8e8c4fe484216d3ab7e2651a .PageQuote > * {
    width: 50%;
    height: 26rem;
  }
}

@media (min-width: 1200px) {
  .HomePageAfd0152e8e8c4fe484216d3ab7e2651a .PageQuote .Title {
    font-size: 400%;
    line-height: 125%;
  }
}